import React, { useState } from 'react'
import BgImg from '../images/samples/blog/980x250.png'
import * as styles from '../templates/profile.module.scss'

const Tabs = () => {
  const [active, setActive] = useState(0)
  return (
    <ul id={styles.ProfileTab}>
      <li
        className={active === 0 ? styles.TabSelected : 'text-muted'}
        onClick={() => setActive(0)}
      > Profile </li>
      <li
        className={active === 1 ? styles.TabSelected : 'text-muted'}
        onClick={() => setActive(1)}
      > Blog Posts </li>
      <li
        className={active === 2 ? styles.TabSelected : 'text-muted'}
        onClick={() => setActive(2)}
      > Blog Comments </li>
      <li
        className={active === 3 ? styles.TabSelected : 'text-muted'}
        onClick={() => setActive(3)}
      > Blog Likes </li>
    </ul>
  )
}

const Profile = () => {
  const admin = true
  return (
    <>
      <section className="full bg-blue-gradient-left m-0">
        <div className="container p-0">
          <header id={styles.Profile}>
            <div className="position-relative d-flex align-items-end">
              <div className="hero position-absolute d-flex justify-content-between w-100 px-5">
                <div className="d-flex align-items-center">
                  <div className="author-image pr-4">
                    <p className="rounded-circle bg-lwart-blue p-5">img</p>
                  </div>
                  <div className="author-name">
                    <div className="d-flex align-items-center">
                      <h1 className="pr-4">Lwart Time</h1> <span className={admin ? 'd-block' : 'd-none'}>admin</span>
                    </div>
                    <div>
                      <span className="pr-4">0 Followers</span> <span>0 Following</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center pt-5">
                  <button type="button" className="btn btn-outline-buttonWhite py-1 px-3"> Follow </button>
                </div>
              </div>
              <div className="w-100">
                {/* <Img fluid={props.image} /> */}
                <img src={BgImg} className="img-fluid w-100"/>
              </div>
            </div>
          </header>
        </div>
        <div className="container bg-white">
          <Tabs/>
        </div>
      </section>
    </>
  )
}

export default Profile
// export default injectIntl(NotFoundPage)
